.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu__title {
  font-family: var(--font-main);
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--color-main);
  margin-bottom: 0.5rem;
}

.menu__heading {
  font-family: var(--font-heading);
  font-weight: 800;
  font-size: 80px;
  letter-spacing: -0.08em;
  color: var(--color-accent);
  text-align: center;
  margin-bottom: 4rem;
  width: 700px;
}

.menu__dish-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.5rem;
}

.menu__button {
  cursor: pointer;
  outline: none;
  border: none;
  background: var(--color-accent);
  -webkit-box-shadow: 0px 38px 50px 17px rgba(234, 0, 37, 0.1);
  box-shadow: 0px 38px 50px 17px rgba(234, 0, 37, 0.1);
  border-radius: 10px;
  width: 188px;
  height: 62px;
  font-family: var(--font-main);
  font-size: 16px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu__button-icon {
  background-color: #ffffff;
  color: var(--color-accent);
  padding: 0.3rem;
  border-radius: 5px;
  margin-left: 0.8rem;
}

@media screen and (max-width: 1000px) {
  .menu__title {
    font-size: 18px;
  }

  .menu__heading {
    font-size: 65px;
    width: 95%;
    margin-bottom: 2rem;
    line-height: 65px;
  }
}

@media screen and (max-width: 550px) {
  .menu__title {
    font-size: 13px;
  }

  .menu__heading {
    font-size: 40px;
    line-height: 40px;
  }
}
