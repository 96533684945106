@import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@700;800&family=Poppins:wght@300;400;500;600;700;800&display=swap');
/* font-family: 'Abhaya Libre', serif;
font-family: 'Poppins', sans-serif; */

:root {
  --font-main: 'Poppins', sans-serif;
  --font-heading: 'Abhaya Libre', serif;

  --color-main: #2a333e;
  --color-accent: #ea0000;
  --color-background: #fff1f1;
}
