.product-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  margin-bottom: 125px;
}

.product-container__image {
  width: 180px;
  height: 180px;
  filter: drop-shadow(0px 100px 80px rgba(0, 0, 0, 0.07))
    drop-shadow(0px 64px 46px rgba(0, 0, 0, 0.05))
    drop-shadow(0px 38px 25px rgba(0, 0, 0, 0.04))
    drop-shadow(0px 20px 13px rgba(0, 0, 0, 0.035))
    drop-shadow(0px 8px 6px rgba(0, 0, 0, 0.02))
    drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.01));
  margin-bottom: 1.8rem;
}

.product-container__rating {
  width: 100px;
  margin-bottom: 0.8rem;
}

.product-container p {
  font-family: var(--font-main);
  font-weight: 400;
  font-size: 15px;
  color: var(--color-main);
  margin-bottom: 0.6rem;
}

.product-container h2 {
  font-family: var(--font-main);
  font-weight: 700;
  font-size: 28px;
  color: var(--color-main);
  margin-bottom: 1.8rem;
}

.product-container button {
  cursor: pointer;
  font-family: var(--font-main);
  width: 202px;
  height: 47px;
  color: #fff;
  background: var(--color-accent);
  box-shadow: 0px 100px 80px rgba(234, 0, 37, 0.1),
    0px 65px 47px rgba(234, 0, 37, 0.07), 0px 38px 25px rgba(234, 0, 37, 0.06),
    0px 20px 13px rgba(234, 0, 37, 0.05), 0px 8px 6px rgba(234, 0, 37, 0.03),
    0px 2px 3px rgba(234, 0, 37, 0.02);
  border-radius: 10px;
  outline: none;
  border: none;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.04em;
}
