.tab {
  background-color: var(--color-background);
  padding: 4rem;
  width: 800px;
  position: relative;
  border-radius: 20px;
  position: relative;
  display: none;
}

.tab--active {
  display: block;
}

.tab__leaf-image {
  position: absolute;
  top: -30px;
  right: 175px;
}

.tab__faster-delivery {
  background-color: #ffdad7;
  border: solid #fcccc9 2px;
  width: 350px;
  display: flex;
  font-family: var(--font-main);
  font-weight: 300;
  font-size: 16px;
  color: var(--color-main);
  padding: 0.5rem 1.5rem;
  border-radius: 40px;
  margin-bottom: 2rem;
}

.tab__delivery-man {
  margin-left: 1rem;
  height: 25.28px;
}

.tab__title {
  font-family: var(--font-heading);
  font-weight: 800;
  font-size: 80px;
  letter-spacing: -0.06em;
  color: var(--color-accent);
  line-height: 62px;
  width: 560px;
  margin-bottom: 2rem;
}

.tab__description {
  font-family: var(--font-main);
  font-weight: 500;
  font-size: 14px;
  line-height: 34px;
  color: var(--color-main);
  width: 350px;
  margin-bottom: 2.5rem;
}

.tab__image {
  position: absolute;
  width: 300px;
  bottom: -50px;
  right: -50px;
}

.tab__button {
  width: 171px;
  height: 59px;
  background: #2a333e;
  box-shadow: 0px 100px 80px rgba(68, 68, 68, 0.1),
    0px 65px 47px rgba(68, 68, 68, 0.07), 0px 38px 25px rgba(68, 68, 68, 0.06),
    0px 20px 13px rgba(68, 68, 68, 0.05), 0px 8px 6px rgba(68, 68, 68, 0.039),
    0px 1.8px 3px rgba(68, 68, 68, 0.02);
  border-radius: 10px;
  outline: none;
  border: none;
  font-family: var(--font-main);
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

@media screen and (max-width: 1100px) {
  .tab {
    width: 100%;
  }

  .tab__title {
    font-size: 65px;
    width: 100%;
  }

  .tab__image {
    width: 200px;
    bottom: -60px;
    right: 0px;
  }
}

@media screen and (max-width: 800px) {
  .tab {
    padding: 2rem;
  }

  .tab__title {
    font-size: 65px;
    width: 100%;
  }

  .tab__faster-delivery {
    font-size: 12px;
    width: 300px;
  }

  .tab__delivery-man {
    height: 20px;
  }

  .tab__description {
    width: 100%;
    font-size: 12px;
    line-height: 25px;
  }

  .tab__leaf-image {
    width: 50px;
    top: -15px;
    right: 100px;
  }
}

@media screen and (max-width: 600px) {
  .tab__title {
    font-size: 50px;
    line-height: 48px;
  }

  .tab__image {
    width: 175px;
  }

  .tab__button {
    font-size: 14px;
    width: 140px;
    height: 50px;
  }

  .tab {
    padding: 1.5rem;
  }
}

@media screen and (max-width: 450px) {
  .tab__image {
    width: 125px;
  }

  .tab__faster-delivery {
    font-size: 10px;
    width: 250px;
  }

  .tab__title {
    font-size: 35px;
    line-height: 38px;
  }
}
