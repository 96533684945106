* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

a {
  color: unset;
  text-decoration: none;
}

.section__padding {
  padding: 0 14rem;
}

.section__margin {
  margin: 16rem 0;
}

@media screen and (max-width: 1600px) {
  .section__padding {
    padding: 0rem 8rem;
  }

  .section__margin {
    margin: 12rem 0;
  }
}

@media screen and (max-width: 800px) {
  .section__padding {
    padding: 0rem 2rem;
  }

  .section__margin {
    margin: 8rem 0;
  }
}

@media screen and (max-width: 600px) {
  .section__padding {
    padding: 0rem 1.5rem;
  }
}
@media screen and (max-width: 400px) {
  .section__padding {
    padding: 0rem 1rem;
  }
}

.scale-up-tr {
  -webkit-animation: scale-up-tr 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-tr 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-tr {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}
@keyframes scale-up-tr {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}
