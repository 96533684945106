.mob-app {
  background-color: var(--color-background);
  padding: 4rem 0;
}

.mob-app__container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.mob-app__info {
  width: 400px;
}

.mob-app__image {
  width: 380px;
  transform: translateY(40px);
}

.mob-app__info-title {
  font-family: var(--font-main);
  font-weight: 700;
  font-size: 20px;
  letter-spacing: -0.04em;
  text-transform: uppercase;
  color: var(--color-main);
  margin-bottom: 0.5rem;
}

.mob-app__info-heading {
  font-family: var(--font-heading);
  font-weight: 800;
  font-size: 62px;
  letter-spacing: -0.08em;
  color: var(--color-accent);
  margin-bottom: -0.5rem;
}

.mob-app__info-description {
  font-family: var(--font-main);
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: var(--color-main);
  width: 475px;
  margin: 1.5rem 0 3rem 0;
}

.mob-app__info-buttons {
  display: flex;
  justify-content: space-between;
  width: 400px;
}

.mob-app__info-button {
  padding: 0;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
}

@media screen and (max-width: 1000px) {
  .mob-app__container {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 800px) {
  .mob-app__info-title {
    font-size: 16px;
    letter-spacing: -0.04em;
  }

  .mob-app__info-heading {
    font-size: 45px;
    letter-spacing: -0.08em;
  }

  .mob-app__info-description {
    font-size: 14px;
    line-height: 26px;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .mob-app__image {
    width: 280px;
  }

  .mob-app__info {
    width: 100%;
  }
  .mob-app__info-buttons {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 125px;
  }
}
