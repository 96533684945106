.dish {
  width: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--font-main);
  border: 1.2px solid #efefef;
  border-radius: 24px;
  margin: 1rem;
  padding: 2rem;
  position: relative;
}

.dish__image {
  width: 185px;
  margin-bottom: 1.5rem;
}

.dish__rating {
  width: 102px;
  margin-bottom: 0.5rem;
  align-self: flex-start;
}

.dish__title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: var(--color-main);
  margin-bottom: 0.5rem;
  align-self: flex-start;
}

.dish__description {
  font-size: 16px;
  letter-spacing: -0.01em;
  color: var(--color-main);
  margin-bottom: 3rem;
}

.dish__price {
  font-weight: 500;
  font-size: 20px;
  color: var(--color-main);
  position: absolute;
  left: 2rem;
  bottom: 2rem;
}

.dish__buttons {
  position: absolute;
  right: 2rem;
  bottom: 2rem;
}

.dish__button {
  width: 32px;
  height: 32px;
  outline: none;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.dish__button-svg {
  display: inline-block;
  vertical-align: middle;
}

.dish__button--like {
  background-color: var(--color-accent);
  color: #fff;
  box-shadow: 0px 100px 80px rgba(234, 0, 37, 0.1),
    0px 64px 46px rgba(234, 0, 37, 0.07), 0px 38px 25px rgba(234, 0, 37, 0.06),
    0px 20px 13px rgba(234, 0, 37, 0.05), 0px 8px 6px rgba(234, 0, 37, 0.04),
    0px 2px 3px rgba(234, 0, 37, 0.02);
}

.dish__button--share {
  background-color: var(--color-main);
  color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  margin-left: 0.7rem;
}

@media screen and (max-width: 450px) {
  .dish {
    width: 100%;
  }
}
