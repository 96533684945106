.testimonials {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonials__title {
  font-family: var(--font-main);
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--color-main);
  margin-bottom: 1.5rem;
}

.testimonials__heading {
  position: relative;
  font-family: var(--font-heading);
  font-weight: 800;
  font-size: 80px;
  line-height: 60px;
  letter-spacing: -0.08em;
  color: var(--color-accent);
  margin-bottom: 5rem;
}

.testimonials__heading-underline {
  position: absolute;
  top: 75px;
  right: 0px;
}

.testimonials__container {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

@media screen and (max-width: 1000px) {
  .testimonials__title {
    font-size: 18px;
    margin-bottom: 1rem;
  }
  .testimonials__heading {
    font-size: 65px;
  }

  .testimonials__heading-underline {
    width: 150px;
    top: 70px;
  }
}

@media screen and (max-width: 550px) {
  .testimonials__title {
    font-size: 13px;
    margin-bottom: 0.2rem;
  }

  .testimonials__heading {
    font-size: 40px;
    margin-bottom: 0rem;
  }

  .testimonials__heading-underline {
    width: 100px;
    top: 55px;
  }
}
